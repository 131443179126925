<template>
  <div class="auth-desc">
    <div class="desc-title">
      <i class="el-icon-chat-dot-round"></i> 授权说明
    </div>
    <ol>
      <li>
        淘宝联盟限制每次授权有效期为30天，到期前请及时重新授权，否则将导致淘客相关功能失效。
      </li>
      <li>同一淘宝账号仅能授权给一个账号，如需重复授权则需要先从原先账号中解除授权</li>
      <li>
        目前暂不限制可授权的淘宝账号数量，但不排除未来可能会限制授权数量的可能性
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "auth-desc",
};
</script>

<style lang="less">
.auth-desc {
  margin: 1rem 0;
  padding: 1rem 0.8rem;
  border: 1px #cccccc solid;
  border-radius: 5px;
  background-color: #fafafa;

  .desc-title {
    font-weight: 600;
    color: red;
  }
}
</style>